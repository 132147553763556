import imageCompression from "browser-image-compression";
import _, { get } from "lodash";
import { useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { linkAccount } from "./axle";
import { MAX_FILE_SIZE, MAX_MEGAPIXELS } from "./constants";

export const constructUriWithSearchParams = (urlAsString, paramsToAdd) => {
  let urlAsObject = new URL(urlAsString);

  for (const [key, value] of Object.entries(paramsToAdd)) {
    urlAsObject.searchParams.append(key, value);
  }

  return urlAsObject.toString();
};

export const generateLinkErrorMessage = (linkError) => {
  return `The Axle service could not ${linkError} for the specified carrier.`;
};

export const generateManualErrorMessage = (manualError) => {
  return `The Axle service could not ${manualError} the uploaded document`;
};

export const generateIgnitionErrorMessage = (ignitionError) => {
  return `Invalid token provided. Please ${ignitionError}`;
};

export const checkZipCode = (str) => {
  // Check if the string consists of exactly 5 numeric characters
  return /^\d{5}$/.test(str);
};

export const checkIgnitionToken = (ignitionToken) => {
  const ignitionTokenValidation = {
    message: "",
    status: true,
  };
  // Check if the string starts with "ign_"
  const startsWithIgn = _.startsWith(ignitionToken, "ign_");

  if (!startsWithIgn) {
    _.set(
      ignitionTokenValidation,
      "message",
      generateIgnitionErrorMessage("ensure ignition token starts with 'ign_'")
    );
    _.set(ignitionTokenValidation, "status", false);

    return ignitionTokenValidation;
  }

  // Check if the string contains exactly 25 characters
  const validLength = _.size(ignitionToken) === 25;

  if (!validLength) {
    _.set(
      ignitionTokenValidation,
      "message",
      generateIgnitionErrorMessage(
        "ensure ignition token is 25 characters long"
      )
    );
    _.set(ignitionTokenValidation, "status", false);
  }

  return ignitionTokenValidation;
};

export const getUserAndMetadataFromQueryParams = (obj, userKeys) => {
  const extractedUserItems = _.pick(obj, userKeys);
  const extractedMetadataKeys = _.difference(_.keys(obj), userKeys);
  const extractedMetadataItems = _.pick(obj, extractedMetadataKeys);

  // Parse phone number
  if (extractedUserItems.phone) {
    extractedUserItems.phone = parsePhoneNumber(
      extractedUserItems.phone,
      "US"
    )?.number;
  }

  // Return extracted user fields
  return {
    userQueryParams: extractedUserItems,
    metadataQueryParams: extractedMetadataItems,
  };
};

/**
 *
 * @param {*} file File object to compress
 * @param {*} mimeType Mime type of the image
 * @returns
 */
export const compressImage = async (file, mimeType) => {
  console.log(
    `Compressing image to max ${MAX_MEGAPIXELS} megapixels and up to ${MAX_FILE_SIZE} MB in size...`
  );
  try {
    // Calculate the original size in MB
    const sizeInMB = file.size / (1024 * 1024);
    console.log(`Original file size: ${sizeInMB.toFixed(2)} MB`);

    // Get the image dimensions
    const [img, canvas] = await imageCompression.drawFileInCanvas(file);
    const width = img.width;
    const height = img.height;
    const currentMegaPixels = (width * height) / 1000000;

    console.log(`Original megapixels: ${currentMegaPixels.toFixed(2)}`);

    // If image is already under both thresholds, return the original file
    if (currentMegaPixels <= MAX_MEGAPIXELS && sizeInMB <= MAX_FILE_SIZE) {
      console.log(
        "Image already meets size requirements. No compression needed."
      );
      return file;
    }

    // Calculate the scale factor only if we need to reduce megapixels
    const scaleFactor =
      currentMegaPixels > MAX_MEGAPIXELS
        ? Math.sqrt(MAX_MEGAPIXELS / currentMegaPixels)
        : 1;
    const targetWidth = Math.round(width * scaleFactor);
    const targetHeight = Math.round(height * scaleFactor);

    // Compression options
    const options = {
      maxSizeMB: MAX_FILE_SIZE,
      maxWidthOrHeight: Math.max(targetWidth, targetHeight),
      useWebWorker: true,
      fileType: mimeType,
    };

    // Compress the image
    const compressedFile = await imageCompression(file, options);

    // Calculate the compressed size in MB
    const compressedFileSizeInMB = compressedFile.size / (1024 * 1024);
    console.log(
      `Compressed file size: ${compressedFileSizeInMB.toFixed(2)} MB`
    );

    return compressedFile;
  } catch (error) {
    console.error("Error compressing image:", error);
    throw error;
  }
};

// Define a custom hook that can be used by both EnterMfa and Login components
// This hook handles getAccount logic, including retries and error handling
export const useAccountHandler = (
  {
    ignitionToken,
    setAccountInfo,
    loginInformation,
    nextStep,
    setLinkError,
    setShowNav,
    retryGetAccountAttempts,
  },
  setLoginInformation
) => {
  const retryGetAccount = async () => {
    if (retryGetAccountAttempts < 1) {
      retryGetAccountAttempts++;
      console.log("Received an error from getAccount, retrying...");
      await getAccount();
    } else {
      setLinkError("retrieve account details");
      nextStep("carrier-error");
    }
  };

  const handleUnsuccessfulGetAccount = async (error) => {
    switch (get(error, "code")) {
      case 400:
        if (
          error.message ===
          "Sorry, your insurance account or policy has not been set up for online access."
        ) {
          nextStep("account-pending");
        } else {
          console.error("Unknown 400 error from getAccount: ", error);
          setLinkError("retrieve account details");
          nextStep("carrier-error");
        }
        break;
      case 503:
        nextStep("carrier-maintenance");
        break;
      case 401:
      case 403:
      case 500:
        console.log(`${get(error, "code")} error from getAccount: `, error);
        await retryGetAccount();
        break;
      default:
        console.error("Unknown error from getAccount: ", error);
        await retryGetAccount();
    }
  };

  const getAccount = async () => {
    // Fetch account
    const [linkAccountResponse, linkAccountError] = await linkAccount(
      ignitionToken
    );

    if (linkAccountError) {
      // Handle unsuccessful getAccount
      await handleUnsuccessfulGetAccount(linkAccountError);

      // Restore the nav bar
      setShowNav(true);
    } else {
      // If no compatible policies on account send to "no-policies"
      if (get(linkAccountResponse, "policies.length", 0) === 0) {
        nextStep("no-policies");
        return;
      }

      // Save account info to state
      setAccountInfo(linkAccountResponse);

      // setLoginInformation is not passed in at the EnterMfa step, so only perform this logic when the previous step was Login
      if (setLoginInformation) {
        // Set login result to link
        setLoginInformation({
          ...loginInformation,
          result: "link",
          resultDetail: "link",
        });
      }

      // Go to policy selection step
      nextStep("confirm");

      // Restore the nav bar
      setShowNav(true);
    }
  };

  return getAccount;
};
