import { get } from "lodash";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import Loader from "../components/Loader";
import { updateIgnition } from "../lib/axle";
import {
  constructUriWithSearchParams,
  generateLinkErrorMessage,
  generateManualErrorMessage,
} from "../lib/utility";

const Failed = ({
  step,
  session,
  client,
  previousStep,
  linkError,
  manualError,
  posthog,
  nextStep,
}) => {
  const [error, setError] = useState(null);

  const [isRedirectEnabled, setRedirectEnabled] = useState(
    session.redirectUri || session.origin
  );

  let message = "";

  switch (previousStep) {
    case "carrier-error":
      message = generateLinkErrorMessage(linkError);
      break;
    case "manual-document-error":
      message = generateManualErrorMessage(manualError);
      break;
    default:
      message = "The Axle service cannot be reached. Please try again later.";
      break;
  }

  useEffect(() => {
    posthog.capture("$pageview", { step });
    const fail = async () => {
      // Construct webhook event
      const lastEvent = {
        id: `event_${nanoid()}`,
        type: `ignition.errored`,
        data: {
          token: session.id,
          message,
          user: session.user,
          client: client.id,
        },
        createdAt: new Date().toISOString(),
      };

      // Update Ignition session
      const params = {
        status: "errored",
        lastEvent,
      };
      const [updateIgnitionResponse, updateIgnitionError] =
        await updateIgnition(session.id, params);

      if (updateIgnitionError) {
        switch (get(updateIgnitionError, "code")) {
          case 500:
            console.log(JSON.stringify(updateIgnitionError));
            nextStep("axle-error");
            return;
          default:
            nextStep("connection-error");
            return;
        }
      }

      if (isRedirectEnabled) {
        await sendEventAndRedirect();
      }
    };
    trackPromise(fail());
  }, []);

  const sendEventAndRedirect = async () => {
    // Params for redirectUri
    const paramsToAdd = {
      status: "error",
      message: encodeURIComponent(message),
      client: client.id,
    };

    // Send message to parent window if initialized in an iframe
    if (session.origin) {
      window.parent.postMessage(paramsToAdd, session.origin);
      console.log(
        "Message sent to parent window",
        JSON.stringify(paramsToAdd, null, 2)
      );
    }

    if (session.redirectUri) {
      const constructedUri = constructUriWithSearchParams(
        session.redirectUri,
        paramsToAdd
      );
      // await new Promise((resolve) => setTimeout(resolve, 1500));
      window.location.href = constructedUri;
    }
  };

  return (
    <>
      {error && (
        <div
          className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-20 flex flex-col gap-y-4"
          role="status"
        >
          {" "}
          {error}{" "}
        </div>
      )}

      {isRedirectEnabled ? (
        <Loader isOpen={true} />
      ) : (
        <div className="flex flex-col justify-center grow gap-y-8 -mt-12 p-8">
          <div className="flex justify-center">
            <div
              style={{ backgroundImage: `url("./icons/manual-required.svg")` }}
              className={`flex-none h-32 w-full bg-contain bg-no-repeat bg-center`}
              aria-label={step}
            ></div>
          </div>
          <div className="flex flex-col justify-center gap-y-4 text-center text-black">
            <h3 className="font-bold text-xl">
              {get(session, "config.failed.header") ??
                "Sorry, there is an error."}
            </h3>
            <p className="text-lg">
              {get(session, "config.failed.subheader") ?? (
                <>
                  The <b>{client.displayName}</b> team will take it from here.
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Failed;
