import _, { get, size } from "lodash";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import Loader from "../components/Loader";
import { updateIgnition } from "../lib/axle";
import { constructUriWithSearchParams } from "../lib/utility";

const Exit = ({
  step,
  session,
  client,
  loginInformation,
  history,
  previousStep,
  posthog,
  nextStep,
}) => {
  const [isRedirectEnabled, setRedirectEnabled] = useState(
    session.redirectUri || session.origin
  );

  if (previousStep === "exit-confirmation") {
    if (size(history) > 1) {
      previousStep = history[size(history) - 2];
    } else {
      previousStep = "consent";
    }
  }

  if (previousStep === "exit-survey") {
    if (size(history) > 2) {
      previousStep = history[size(history) - 3];
    } else {
      previousStep = "consent";
    }
  }

  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier: get(loginInformation, "carrier.id"),
    });
    const exit = async () => {
      // Construct webhook event
      const lastEvent = {
        id: `event_${nanoid()}`,
        type: `ignition.exited`,
        data: {
          token: session.id,
          // config: session.config,
          step: previousStep,
          user: session.user,
          client: client.id,
        },
        createdAt: new Date().toISOString(),
      };

      // Update Ignition session
      const params = {
        status: "exited",
        lastEvent,
      };

      const [updateIgnitionResponse, updateIgnitionError] =
        await updateIgnition(session.id, params);

      if (updateIgnitionError) {
        switch (get(updateIgnitionError, "code")) {
          case 500:
            console.log(JSON.stringify(updateIgnitionError));
            nextStep("axle-error");
            return;
          default:
            nextStep("connection-error");
            return;
        }
      }

      if (isRedirectEnabled) {
        await sendEventAndRedirect();
      }
    };
    trackPromise(exit());
  }, []);

  const sendEventAndRedirect = async () => {
    // Create params for postMessage or redirectUri
    const paramsToAdd = {
      status: "exit",
      step: previousStep,
      client: client.id,
    };

    // Send message to parent window if initialized in an iframe
    if (session.origin) {
      window.parent.postMessage(paramsToAdd, session.origin);
      console.log(
        "Message sent to parent window",
        JSON.stringify(paramsToAdd, null, 2)
      );
    }

    // If redirectUri, change window location to redirect
    if (session.redirectUri) {
      const constructedUri = constructUriWithSearchParams(
        session.redirectUri,
        paramsToAdd
      );
      window.location.href = constructedUri;
    }
  };

  return (
    <>
      {isRedirectEnabled ? (
        <Loader isOpen={true} />
      ) : (
        <div className="flex flex-col justify-center grow gap-y-8 -mt-12 p-8">
          <div className="flex justify-center">
            <div
              style={{ backgroundImage: `url("./icons/exit.svg")` }}
              className={`flex-none h-32 w-full bg-contain bg-no-repeat bg-center`}
              aria-label={step}
            ></div>
          </div>
          <div className="flex flex-col justify-center gap-y-4 text-center text-black">
            <h3 className="font-bold text-xl">
              {_(session).get("config.exit.header") ?? "Thanks!"}
            </h3>
            <p className="text-xl">
              {_(session).get("config.exit.subheader") ??
                `${client.displayName} will reach out to complete verification.`}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Exit;
