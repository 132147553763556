import { get, has, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { ManualDropzone } from "../components/ManualDropzone";
import {
  generateSignedUrl,
  updatePolicy,
  uploadDocument,
  upsertPolicyElements,
} from "../lib/axle";

const ManualPolicyDocument = ({
  step,
  nextStep,
  accountInfo,
  setAccountInfo,
  policyInfo,
  setPolicyInfo,
  loginInformation,
  setLoginInformation,
  session,
  posthog,
  setManualError,
  setShowNav,
}) => {
  const ignitionToken = session.id;
  //Local error state used only within this component.
  //Just replaced within component [error, setError] = useState(null);
  //with [documentStatus, setDocumentStatus] = useState(null);
  const [documentStatus, setDocumentStatus] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [filePath, setFilePath] = useState("");
  const [hasFile, setHasFile] = useState(false);

  let loadingSteps = ["Uploading your document"];

  const uploadWrapper = async () => {
    if (!hasFile) {
      alert("Please upload a document!");
      return;
    }

    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    const [signedUrlResponse, signedUrlResponseError] = await generateSignedUrl(
      ignitionToken,
      policyInfo.id,
      filePath
    );

    if (signedUrlResponseError) {
      switch (get(signedUrlResponseError, "code")) {
        case 500:
          setManualError(get(signedUrlResponseError, "message"));
          nextStep("manual-document-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    if (isEmpty(get(signedUrlResponse, "signedUrl"))) {
      console.error("Signed URL is empty");
      setManualError("process");
      nextStep("manual-document-error");
      return;
    }

    const [documentUploadResponse, documentUploadResponseError] =
      await uploadDocument(signedUrlResponse.signedUrl, fileData);

    if (documentUploadResponseError) {
      switch (get(documentUploadResponseError, "code")) {
        case 500:
          setManualError(documentUploadResponseError.message);
          nextStep("manual-document-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    const documentKey = signedUrlResponse.objectKey;
    let documentsObject = [
      {
        source: "user",
        name: filePath,
        key: documentKey,
        createdAt: new Date().toISOString(),
        type: null,
        effectiveDate: null,
        issuedDate: null,
      },
    ];

    let policyId = policyInfo.id;
    let accountId = accountInfo.id;

    setLoginInformation({
      ...loginInformation,
      result: "manual",
      resultDetail: "policy-form",
    });

    // Setting account and policy info
    setAccountInfo({ id: accountId });
    setPolicyInfo({ id: policyId });

    // Updating policy with documentKey...
    const [updatedPolicy, updatedPolicyError] = await upsertPolicyElements(
      ignitionToken,
      policyId,
      {
        account: accountId,
        documents: documentsObject,
      }
    );

    if (updatedPolicyError) {
      switch (get(updatedPolicyError, "code")) {
        case 500:
          setManualError(updatedPolicyError.message);
          nextStep("manual-document-error");
          return;

        default:
          nextStep("connection-error");
          return;
      }
    }

    setDocumentStatus("success");

    nextStep("success");
    return;
  };

  const uploadFile = async () => {
    await trackPromise(uploadWrapper(), "carrier-loader");

    // Show nav buttons after CarrierLoader is unrendered
    setShowNav(true);
  };

  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  return (
    <>
      <ManualDropzone
        session={session}
        loginInformation={loginInformation}
        uploadFile={uploadFile}
        loadingSteps={loadingSteps}
        setFileData={setFileData}
        setFilePath={setFilePath}
        setHasFile={setHasFile}
        documentStatus={documentStatus}
      />
    </>
  );
};

export default ManualPolicyDocument;
