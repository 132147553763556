export const S3_IMAGES_BASE_URL = "https://axle-labs-assets.s3.amazonaws.com";
export const CREDIT_CARD_IMAGE_BASE_S3 = "creditCardLogos";

export const DEFAULT_CREDIT_CARD_IMAGE = `${S3_IMAGES_BASE_URL}/${CREDIT_CARD_IMAGE_BASE_S3}`;
export const DEFAULT_CREDIT_CARD_IMAGE_GENERIC = `${S3_IMAGES_BASE_URL}/${CREDIT_CARD_IMAGE_BASE_S3}/generic.svg`;

// Carriers that require zipcode input during login
export const CARRIERS_WITH_ZIPCODE_LOGIN = ["aaa", "connect-by-amfam"];

export const SENTRY_TAG_IGNITION_TOKEN = "ignitionToken";
export const SENTRY_TAG_CLIENT_ID = "clientId";
