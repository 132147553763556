import axios from "axios";
import { get } from "lodash";
import { DMV_LOCAL, IGNITION_BACKEND_LOCAL } from "./constants";

const ibBaseUrl = IGNITION_BACKEND_LOCAL
  ? "http://localhost:4000/dev"
  : `https://${
      process.env.REACT_APP_STAGE === "prod"
        ? "api"
        : process.env.REACT_APP_STAGE
    }.axle.insure/ignition-backend`;

const dmvBaseUrl = DMV_LOCAL
  ? "http://localhost:4000/dev"
  : `https://${
      process.env.REACT_APP_STAGE === "prod"
        ? "api"
        : process.env.REACT_APP_STAGE
    }.axle.insure`;

const fetchClient = async (url, options = {}) => {
  try {
    const response = await fetch(url, options && options);

    const body = await response.json();

    if (!response.ok) {
      return [
        null,
        {
          code: get(response, "status", 500),
          message: get(body, "message", null),
        },
      ];
    }

    const data = body.data;
    return [data, null]; // Success response with no error
  } catch (error) {
    if (!error.response) {
      // Network error or connection was interrupted
      console.error("Connection interrupted");
      return [null, { code: null, message: "Connection interrupted" }];
    }

    return [
      null,
      { code: get(error, "status", 500), message: get(error, "message", null) },
    ]; // Return null data with error object
  }
};

const axiosClient = async (url, options = {}) => {
  try {
    const config = {
      url,
      ...options,
    };
    const response = await axios.request(config);

    const data = response.data.data;

    if (get(response, "status") !== 200) {
      return [
        null,
        {
          code: get(response, "status", 500),
          message: get(data, "message", null),
        },
      ];
    }

    return [data, null]; // Success response with no error
  } catch (error) {
    // If the error is a network error, prompt the user with a retry
    if (axios.isAxiosError(error) && get(error, "code") === "ERR_NETWORK") {
      console.error("Connection interrupted");
      return [null, { code: null, message: "Connection interrupted" }];
    }

    //If axios has API `response` error message, (i.e. `message` such as "Oops..." coming from Axle API), we should use that
    //If error occurred to due implementation error (e.g. axios config was malformed), we will not have response message
    const errorMessage = get(
      error,
      "response.data.message",
      get(error, "message", null)
    );
    const errorCode = get(error, "response.status", get(error, "status", null));

    return [null, { code: errorCode, message: errorMessage }];
  }
};

const callAxleService = async (url, options = {}, httpClient = "fetch") => {
  switch (httpClient) {
    case "axios":
      return await axiosClient(url, options);
    case "fetch":
      return await fetchClient(url, options);
  }
};

/**
 * Retrieve the session data for a given ignition token
 * @param ignitionToken - The unique token for the Ignition session.
 * @returns an Ignition Session object.
 */
export const getSession = async (ignitionToken) => {
  return await callAxleService(`${ibBaseUrl}/session/${ignitionToken}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  });
};

/**
 * It makes a GET request to the DMV API to get a client's information
 * @param clientId - The id of the client you want to get.
 * @returns The client object
 */
export const getClient = async (clientId) => {
  return await callAxleService(`${dmvBaseUrl}/clients/${clientId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * It makes a GET request to the Ignition API to get the configuration of a client
 * @param clientId - The client ID of the client you want to get the ignition config for.
 * @returns the data from the response body.
 */
export const getClientWithIgnitionConfig = async (clientId) => {
  return await callAxleService(
    `${dmvBaseUrl}/clients/${clientId}/ignition-config`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

/**
 * `getAccount` is a function that takes in a carrier, username, and password and returns a JSON object
 * with the account information
 * @param ignitionToken - The unique token for the Ignition session.
 * @param carrier - The name of the carrier you want to get the account for.
 * @param username - The username of the account you want to retrieve.
 * @param password - The password for the account.
 */
export const login = async (
  ignitionToken,
  carrier,
  username,
  password,
  zipcode
) => {
  return await callAxleService(`${ibBaseUrl}/login`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({
      carrier,
      username,
      password,
      zipcode,
    }),
  });
};

/**
 * It takes an ignition token and returns an account object
 * @param ignitionToken - The token you received from the user when they logged in.
 * @returns the data object from the body of the response.
 */
export const linkAccount = async (ignitionToken) => {
  return await callAxleService(`${ibBaseUrl}/accounts`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  });
};

/**
 * It returns a list of available MFA options for the user.
 * @param ignitionToken - The unique token for the Ignition session.
 * @param carrier - The name of the carrier.
 */
export const getMfaOptions = async (ignitionToken, carrier) => {
  return await callAxleService(`${ibBaseUrl}/mfa/options`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({
      carrier,
    }),
  });
};

/**
 * Send an MFA code to the user
 * @param ignitionToken - The unique token for the Ignition session.
 * @param carrier - The carrier that the user is trying to sign up with.
 * @param type - The type of MFA that you want to send.
 * @param id - The id of the MFA device
 */
export const sendMfa = async (ignitionToken, carrier, type, id) => {
  return await callAxleService(`${ibBaseUrl}/mfa/send`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({
      carrier,
      type,
      id,
    }),
  });
};

/**
 * It takes in a carrier, username, type, and code and sends it to the API
 * @param ignitionToken - The unique token for the Ignition session.
 * @param carrier - The carrier that the user is trying to log in to.
 * @param type - The type of MFA code you are entering.
 * @param code - The code that you received from the MFA device.
 * @returns The data object is being returned.
 */
export const enterMfa = async (ignitionToken, carrier, type, code) => {
  return await callAxleService(`${ibBaseUrl}/mfa/enter`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({
      carrier,
      type,
      code,
    }),
  });
};

/**
 * It links a policy to an account.
 * @param ignitionToken - The unique token for the Ignition session.
 * @param policyNumber - The policy number of the policy you want to link to the account.
 */
export const linkPolicy = async (ignitionToken, policyType, policyNumber) => {
  return await callAxleService(`${ibBaseUrl}/policies`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({
      policyType,
      policyNumber,
    }),
  });
};

export const linkDocuments = async (
  ignitionToken,
  policyType,
  policyNumber,
  policy
) => {
  return await callAxleService(`${ibBaseUrl}/documents`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({
      policyType,
      policyNumber,
      policy,
    }),
  });
};

/**
 * This function stubs an account for a carrier.
 * @param ignitionToken - The token you received from the login call.
 * @param account - {
 * @returns The stubbed account
 */
export const stubAccount = async (ignitionToken, account, processDetail) => {
  return await callAxleService(`${ibBaseUrl}/stub/accounts`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "x-process-detail": processDetail,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    // Need to standardize these for all carriers...
    body: JSON.stringify(account),
  });
};

export const updateAccount = async (ignitionToken, accountId, account) => {
  return await callAxleService(`${ibBaseUrl}/accounts/${accountId}`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify(account),
  });
};

/**
 * It takes a policy object and returns a stubbed policy object
 * @param policy - This is the policy object that you want to stub.
 * @returns The stubbed policy is being returned.
 */
export const stubPolicy = async (ignitionToken, policy, processDetail) => {
  return await callAxleService(`${ibBaseUrl}/stub/policies`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "x-process-detail": processDetail,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    // Need to standardize these for all carriers...
    body: JSON.stringify(policy),
  });
};

/**
 * It takes an ignition token, policy number, filename, and filetype, and returns a document object
 * @param ignitionToken - The token you received from the login function
 * @param policyId - policyId
 * @param account - accountId
 * @param policy - policy object
 * @returns The document ID
 */
export const updatePolicy = async (
  ignitionToken,
  policyId,
  account,
  policy
) => {
  return await callAxleService(`${ibBaseUrl}/policies/${policyId}`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({
      account,
      ...policy,
    }),
  });
};

/**
 * It takes an ignition token, a policy ID, and a policy object, and then it updates the policy with
 * the new policy object
 * @param ignitionToken - The token you received from the login function
 * @param policyId - The ID of the policy you want to update.
 * @param policy - This is the policy object that you want to update.
 * @returns The policy elements are being returned.
 */
export const upsertPolicyElements = async (ignitionToken, policyId, policy) => {
  return await callAxleService(`${ibBaseUrl}/policies/${policyId}`, {
    method: "put",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify(policy),
  });
};

/**
 * Validate if a policy meets certain requirements.
 * @param ignitionToken - token for the ignition session
 * @param policyId - policy ID for the policy you'd like to validate
 * @param validationType - validation type - check backend documentation for supported validation types
 * @returns validation results based on type
 */
export const validatePolicy = async (
  ignitionToken,
  policyId,
  validationType
) => {
  return await callAxleService(`${ibBaseUrl}/policies/${policyId}/validate`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({ type: validationType }),
  });
};

/**
 * It takes an ignition token and a params object, and returns the updated ignition
 * @param ignitionToken - The token that was returned from the createIgnition call.
 * @param params - {
 * @returns the data from the body of the response.
 */
export const updateIgnition = async (ignitionToken, params) => {
  return await callAxleService(`${ibBaseUrl}/ignition/${ignitionToken}`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify(params),
  });
};

/**
 * It takes in a params object, makes a POST request to the Ignition API, and returns the data from the
 * response
 * @param params - {
 * @returns A promise that resolves to the data object from the response body.
 */
export const createIgnition = async (params) => {
  return await callAxleService(`${ibBaseUrl}/ignition`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify(params),
  });
};

/**
 * It takes an account and client, and returns an authCode
 * @param account - The account ID of the user you want to get an auth code for.
 * @param client - The client ID of the application you're using.
 * @returns The authCode is being returned.
 */
export const getAccessToken = async (
  account,
  policies,
  client,
  ignitionToken
) => {
  // We need to show some error to the consumer if this fails
  const [data, error] = await callAxleService(`${ibBaseUrl}/accessTokens`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-ignition-token": ignitionToken,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    body: JSON.stringify({
      client,
      policies,
      account,
    }),
  });

  // Return authCode
  const authCode = get(data, "authCode", null);
  return [authCode, error];
};

/**
 * It takes in an Ignition token, a policy, a filename, and a filetype, and returns a signed URL
 * @param ignitionToken - The token you get from the `/auth` endpoint
 * @param policy - The policy number you want to upload the document to.
 * @param filename - The name of the file you want to upload
 * @param filetype - The file type of the file you're uploading.
 * @returns The signed URL for the file to be uploaded.
 */
export const generateSignedUrl = async (ignitionToken, policy, filepath) => {
  // We need to show some error to the consumer if this fails
  return await callAxleService(
    `${ibBaseUrl}/generateSignedUrl`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      data: JSON.stringify({
        policy,
        filepath,
      }),
    },
    "axios"
  );
};

export const uploadDocument = async (signedUrl, fileData) => {
  return await callAxleService(
    signedUrl,
    {
      method: "put",
      data: fileData,
    },
    "axios"
  );
};

export const processDocuments = async (
  ignitionToken,
  documentKey,
  documentsObject
) => {
  return await callAxleService(
    `${ibBaseUrl}/documents/process`,
    {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      data: JSON.stringify({
        documentKey,
        documentsObject,
      }),
    },
    "axios"
  );
};
