import { get } from "lodash";
import React, { useEffect } from "react";

import { ButtonPrimary, ButtonSecondary } from "../components/Button";
import { AnchorPrimary, AnchorSecondary } from "../components/Anchor";

const LoginSupport = ({
  step,
  nextStep,
  loginInformation,
  session,
  posthog,
}) => {
  const carrier = get(loginInformation, "carrier.id");
  const isShortcutEnabled = get(session, "config.manual.shortcut", false);

  const registerUrl = get(loginInformation, "carrier.registerUrl", false);
  const recoveryUrl = get(loginInformation, "carrier.recoveryUrl", false);

  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier,
    });
  });

  return (
    <>
      <div className="flex flex-col grow gap-y-8 mt-16">
        <div className="flex justify-center">
          <div
            style={{
              backgroundImage: `url("./icons/not-found.svg")`,
            }}
            className={`flex-none h-32 w-full bg-contain bg-no-repeat bg-center`}
            aria-label={step}
          ></div>
        </div>
        <div className="flex flex-col justify-center gap-y-4 text-center text-black">
          <h3 className="font-bold text-xl"> We’re here to help! </h3>
          <p className="text-xl">
            {isShortcutEnabled
              ? "Reset your login information or upload a document to continue"
              : "Reset your login information to continue"}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 mt-auto">
        {recoveryUrl && (
          <AnchorPrimary
            url={recoveryUrl}
            width={"w-full"}
            onClick={() => {
              // Capture posthog event for forgot password
              posthog.capture("forgot-password-click", {
                step,
                carrier,
              });
              nextStep("login");
            }}
            text={"Reset your password"}
          />
        )}
        {registerUrl && (
          <AnchorSecondary
            url={registerUrl}
            width="w-full"
            onClick={() => {
              // Capture posthog event for register
              posthog.capture("register-click", {
                step,
                carrier,
              });
              nextStep("login");
            }}
            outlined={true}
            text={"Set up an online account"}
          />
        )}
        {isShortcutEnabled && (
          <ButtonSecondary
            text={"Upload document manually"}
            width={"w-full"}
            onClick={() => {
              nextStep("manual-account");
            }}
            {...(!recoveryUrl && !registerUrl && { outlined: true })} // If no recovery or register url, make the button outlined secondary the button
          />
        )}
      </div>
    </>
  );
};

export default LoginSupport;
