import {
  ButtonSecondary,
  InterstitialButtonPrimary,
} from "../components/Button";
import { createIgnition, getSession, updateIgnition } from "../lib/axle";
import { identifyPosthogUser } from "../lib/posthog";

// External dependencies
import * as Sentry from "@sentry/browser";
import _, { get } from "lodash";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import Phone, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import { trackPromise } from "react-promise-tracker";
import Loader from "../components/Loader";
import { SENTRY_TAG_IGNITION_TOKEN } from "../constants";
import { checkIgnitionToken } from "../lib/utility";

const Interstitial = ({
  step,
  nextStep,
  session,
  setSession,
  client,
  posthog,
}) => {
  // Initialize state
  const [hasIgnition, setHasIgnition] = useState(false);
  const [interstitialStep, setInterstitialStep] = useState();
  const [hasRequired, setHasRequired] = useState(true);
  const [userFields, setUserFields] = useState({
    ...session.user,
  });
  const [metadataFields, setMetadataFields] = useState({ ...session.metadata });
  const [error, setError] = useState(null);
  const [identitySubmitButtonType, setIdentitySubmitButtonType] = useState("");
  const [loading, setLoading] = useState(true);

  // Reassign state variables into friendly names
  const ignitionConfig =
    _(session).get("config") ?? _(client).get("config.ignitionConfig");
  const logoUrl = _(ignitionConfig).get("interstitial.styles.logo");

  const meetsRequirements = (config, user, metadata) => {
    // Flatten user and metadata together
    const userMetadataMerged = _.merge(user, metadata);
    const userMetdataFieldsConfigFlattened = _([
      _(config).get("interstitial.user-fields"),
      _(config).get("interstitial.metadata-fields"),
    ])
      .chain()
      .flatten()
      .filter((i) => i)
      .value();

    // Initialize meetsRequirements as true and overrideRequirements as false
    let meetsRequirements = true;
    let overrideRequirements = false;

    // Check each required or override item to see if it has value in user or metadata objects
    _(userMetdataFieldsConfigFlattened)
      .chain()
      .filter((item) => item.required || item.override)
      .forEach((item) => {
        if (item.override && userMetadataMerged[item.value]) {
          overrideRequirements = true;
        }
        if (!userMetadataMerged[item.value]) {
          meetsRequirements = false;
        }
      })
      .value();

    // Return meetsRequirement flag
    return meetsRequirements || overrideRequirements ? true : false;
  };

  const createIgnitionSession = async (user, metadata, clientId) => {
    // Build user object from params, have to check each field as empty query params return as null vs undefined
    const buildUser = {
      ...(user.id && {
        id: user.id,
      }),
      ...(user.firstName && {
        firstName: user.firstName,
      }),
      ...(user.lastName && {
        lastName: user.lastName,
      }),
      ...(user.email && {
        email: user.email,
      }),
      ...(user.phone && {
        phone: user.phone,
      }),
    };

    // Build ignition params from client, user, and metadata
    const ignitionParams = {
      clientId: clientId,
      user: _(user).size() ? buildUser : undefined,
      metadata: _(metadata).size() ? metadata : undefined,
    };

    // Create ignition session in differential, returns ignition session id
    const [ignitionResponse, ignitionError] = await createIgnition(
      ignitionParams
    );

    if (ignitionError) {
      switch (get(ignitionError, "code")) {
        case 500:
          console.log(JSON.stringify(ignitionError));
          nextStep("axle-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    Sentry.setTag(SENTRY_TAG_IGNITION_TOKEN, ignitionResponse.id);

    // Get the ignition session created above, and other ignition session details
    const [ignitionSession, ignitionSessionError] = await getSession(
      ignitionResponse.id
    );

    if (ignitionSessionError) {
      switch (get(ignitionSessionError, "code")) {
        case 500:
          console.log(JSON.stringify(ignitionSessionError));
          nextStep("axle-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    // Ensure origin is set in session if provided
    setSession({
      ...ignitionSession,
      origin: session.origin,
    });

    console.log(
      `Updating ignition session ${ignitionSession.id} in createIgnitionSession...`
    );

    // Update the ignition session we created earlier, with status "opened" and lastEvent
    const [updateIgnitionResponse, updateIgnitionError] = await updateIgnition(
      ignitionSession.id,
      {
        status: "opened",
        lastEvent: {
          id: `event_${nanoid()}`,
          type: `ignition.opened`,
          data: {
            token: ignitionSession.id,
            user: ignitionSession.user,
            client: client.id,
          },
          createdAt: new Date().toISOString(),
        },
      }
    );

    if (updateIgnitionError) {
      switch (get(updateIgnitionError, "code")) {
        case 500:
          console.log(JSON.stringify(updateIgnitionError));
          nextStep("axle-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    // Identify user in Posthog
    identifyPosthogUser(posthog, ignitionSession, client);
    return ignitionResponse;
  };

  useEffect(() => {
    posthog.capture("$pageview", { step });
    const setup = async () => {
      try {
        // Check if required fields for user and metadata is available
        const checkHasIgnition = _(session).get("id") ? true : false;

        setHasIgnition(checkHasIgnition);

        const checkRequired = meetsRequirements(
          ignitionConfig,
          _(session).get("user"),
          _(session).get("metadata")
        );

        setHasRequired(checkRequired);

        // Check which interstitial steps are enabled
        const isWelcome = _(ignitionConfig).get("interstitial.welcome.enabled");
        const isIdentity = _(ignitionConfig).get(
          "interstitial.identity.enabled"
        );

        // If welcome is enabled, render welcome
        if (isWelcome) {
          setInterstitialStep("welcome");
          return;
        }

        // If welcome is not enabled and requirements are not met, go to identity step
        if (!checkRequired) {
          if (isIdentity) {
            setInterstitialStep("identity");
            return;
          }
          // If identity is disabled, setError
          setError("Requirements not met. Please update Ignition config.");
          return;
        }

        // Otherwise, create Ignition resource and skip to consent
        if (!checkHasIgnition) {
          await createIgnitionSession(
            _(session).get("user"),
            _(session).get("metadata"),
            _(client).get("id")
          );
        }

        nextStep("consent");
        return;
      } finally {
        setLoading(false);
      }
    };
    trackPromise(setup());
  }, []);

  const welcomeAction = async (e) => {
    // Get action step for button triggering welcomeAction
    const buttonName = e.target.name;

    const actionStep = _(ignitionConfig).get(
      `interstitial.welcome.${buttonName}-action.step`
    );
    const isIdentity = _(ignitionConfig).get("interstitial.identity.enabled");

    // If requirements are not met, go to identity step
    if (!hasRequired) {
      if (isIdentity) {
        setInterstitialStep("identity");
        return;
      }
      // If identity is disabled, setError
      setError("Requirements not met. Please update Ignition config.");
      return;
    }

    // Don't proceed to next step if it is interstitial-identity, instead just rerender component
    if (actionStep === "interstitial-identity") {
      setInterstitialStep("identity");
      return;
    }

    // Create Ignition resource and go to next step specified in action
    if (!hasIgnition) {
      await trackPromise(
        createIgnitionSession(
          _(session).get("user"),
          _(session).get("metadata"),
          _(client).get("id")
        )
      );
    }
    nextStep(actionStep);
  };

  const identityAction = async (e) => {
    e.preventDefault();

    // Initialize error tracker
    let errorMessage = "";
    let hasError = false;

    // Check if no value provided in each required user field
    _(ignitionConfig)
      .chain()
      .get("interstitial.user-fields")
      .filter((item) => item.required)
      .forEach((item) => {
        if (!userFields[item.value]) {
          errorMessage = `You must provide your ${item.placeholder}.`;
          hasError = true;
        }
      })
      .value();

    // Check if no value provided in each required metadata field
    _(ignitionConfig)
      .chain()
      .get("interstitial.metadata-fields")
      .filter((item) => item.required)
      .forEach((item) => {
        if (!metadataFields[item.value]) {
          errorMessage = `You must provide your ${item.placeholder}.`;
          hasError = true;
        }
      })
      .value();

    // If one required field is missing a value, then setError
    if (hasError) {
      setError(errorMessage);
      return;
    }

    // Check if primary or secondary button was submitted
    const buttonName = get(
      e,
      "nativeEvent.submitter.name",
      identitySubmitButtonType
    ); // Note: sometimes e.nativeEvent.submitter is undefined or null. In that case we will use identitySubmitButtonType as backup
    const actionStep = _(ignitionConfig).get(
      `interstitial.identity.${buttonName}-action.step`
    );

    try {
      // Don't proceed to next step if it is interstitial-welcome, instead just rerender component
      if (actionStep === "interstitial-welcome") {
        setInterstitialStep("welcome");
        return;
      }

      // Update or create Ignition with data entered into user and metadata fields
      if (hasIgnition) {
        const [updateIgnitionResponse, updateIgnitionError] =
          await updateIgnition(_(session).get("id"), {
            ...session,
            ...{ user: userFields, metadata: metadataFields },
          });

        if (updateIgnitionError) {
          switch (get(updateIgnitionError, "code")) {
            case 500:
              console.log(JSON.stringify(updateIgnitionError));
              nextStep("axle-error");
              return;
            default:
              nextStep("connection-error");
              return;
          }
        }

        setSession({
          ...session,
          ...{ user: userFields, metadata: metadataFields },
        });
      } else {
        await trackPromise(
          createIgnitionSession(userFields, metadataFields, _(client).get("id"))
        );
      }

      // Go to next step specified in action
      nextStep(actionStep);
    } catch (error) {
      // If 500 send customer to "failed"
      if (
        error.message === "Oops! Something went wrong." ||
        error.message === "Oops something went wrong. Please try again later."
      ) {
        nextStep("failed");
      }
      // Else display error
      setError(error.message);
    }
  };

  const renderSwitch = (step) => {
    switch (step) {
      case "welcome":
        return (
          <>
            <div className="flex">
              <img
                src={logoUrl}
                className="max-h-12 max-w-full h-full w-auto"
              ></img>
            </div>
            <div className="flex flex-col gap-y-8 mt-auto">
              <h3 className="text-4xl text-black font-bold">
                {_(ignitionConfig).get("interstitial.welcome.header")}
              </h3>
              <p className="text-xl text-black mb-4">
                {_(ignitionConfig).get("interstitial.welcome.subheader")}
              </p>
              <div className="flex flex-col gap-y-4">
                {_(ignitionConfig).get(
                  "interstitial.welcome.primary-action"
                ) && (
                  <InterstitialButtonPrimary
                    text={_(ignitionConfig).get(
                      "interstitial.welcome.primary-action.text"
                    )}
                    onClick={welcomeAction}
                    width={"w-full"}
                    color={_(ignitionConfig).get(
                      "interstitial.styles.colors.primary-action"
                    )}
                    name={"primary"}
                  />
                )}
                {_(ignitionConfig).get(
                  "interstitial.welcome.secondary-action"
                ) && (
                  <ButtonSecondary
                    onClick={welcomeAction}
                    name={"secondary"}
                    text={_(ignitionConfig).get(
                      "interstitial.welcome.secondary-action.text"
                    )}
                  />
                )}
              </div>
            </div>
          </>
        );
      case "identity":
        return (
          <>
            <div className="flex">
              <img
                src={logoUrl}
                className="max-h-12 max-w-full h-full w-auto"
              ></img>
            </div>
            <div className="flex flex-col gap-y-2 mt-auto">
              <h3 className="text-2xl text-black font-bold">
                {_(ignitionConfig).get("interstitial.identity.header")}
              </h3>
              <p className="text-lg text-black">
                {_(ignitionConfig).get("interstitial.identity.subheader")}
              </p>
            </div>
            <form
              className="flex flex-col h-full gap-y-8"
              onSubmit={identityAction}
            >
              <div className="flex flex-col gap-y-4 mt-auto">
                {error && (
                  <div
                    className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                    role="status"
                  >
                    {" "}
                    {error}{" "}
                  </div>
                )}
                {_(ignitionConfig)
                  .chain()
                  .get("interstitial.user-fields")
                  .map((item) => {
                    return item.type === "phone" ? (
                      <div className="flex flex-col gap-y-2">
                        <Phone
                          placeholder={item.placeholder}
                          defaultCountry="US"
                          value={userFields[item.value]}
                          className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                          onChange={(e) =>
                            setUserFields({
                              ...userFields,
                              [item.value]: e,
                            })
                          }
                        />
                        {
                          // Check whether phone number is valid
                          userFields[item.value] &&
                          !isPossiblePhoneNumber(
                            userFields[item.value],
                            "US"
                          ) ? (
                            <span className="text-red-700 text-sm">
                              Invalid phone number
                            </span>
                          ) : (
                            <></>
                          )
                        }
                      </div>
                    ) : (
                      <input
                        placeholder={item.placeholder}
                        value={userFields[item.value]}
                        className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                        type={item.type}
                        onChange={(e) =>
                          setUserFields({
                            ...userFields,
                            [item.value]: e.target.value,
                          })
                        }
                      />
                    );
                  })
                  .value()}
                {_(ignitionConfig)
                  .chain()
                  .get("interstitial.metadata-fields")
                  .map((item) => {
                    return (
                      <input
                        placeholder={item.placeholder}
                        value={metadataFields[item.value]}
                        className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                        type={item.type}
                        onChange={(e) =>
                          setMetadataFields({
                            ...metadataFields,
                            [item.value]: e.target.value,
                          })
                        }
                      />
                    );
                  })
                  .value()}
              </div>
              <div className="flex flex-col gap-y-4 mt-auto">
                {_(ignitionConfig).get(
                  "interstitial.identity.primary-action"
                ) && (
                  <InterstitialButtonPrimary
                    text={_(ignitionConfig).get(
                      "interstitial.identity.primary-action.text"
                    )}
                    type={"submit"}
                    width={"w-full"}
                    name={"primary"}
                    color={_(ignitionConfig).get(
                      "interstitial.styles.colors.primary-action"
                    )}
                    onClick={() => setIdentitySubmitButtonType("primary")}
                  />
                )}
                {_(ignitionConfig).get(
                  "interstitial.identity.secondary-action"
                ) && (
                  <ButtonSecondary
                    name={"secondary"}
                    type={"submit"}
                    text={_(ignitionConfig).get(
                      "interstitial.identity.secondary-action.text"
                    )}
                    onClick={() => setIdentitySubmitButtonType("secondary")}
                  />
                )}
              </div>
            </form>
          </>
        );
      default:
        return (
          <>
            {error ? (
              <div className="flex items-center gap-x-4 text-white text-sm rounded-sm bg-red-600 bg- p-3 -mb-1 z-20">
                {error}
              </div>
            ) : (
              <div className="flex items-center gap-x-4 text-white text-sm rounded-sm bg-red-600 bg- p-3 -mb-1 z-20">
                Sorry, this page does not exist!
              </div>
            )}
          </>
        );
    }
  };

  return (
    <>
      {loading ? <Loader isOpen={loading} /> : renderSwitch(interstitialStep)}
    </>
  );
};

export default Interstitial;
